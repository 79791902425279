/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'b3jbS5Gf6TAJutArtK7Zdk.jpg': { uri: '/images/b3jbS5Gf6TAJutArtK7Zdk.jpg' },
'9tR7XMCU2hBQjCBN729ZSX.png': { uri: '/images/9tR7XMCU2hBQjCBN729ZSX.png' },
'e3DRZSuEMFshVBK1Byh5Mb.png': { uri: '/images/e3DRZSuEMFshVBK1Byh5Mb.png' },
'qvw6AfPXLgoX3516dzUJWN.png': { uri: '/images/qvw6AfPXLgoX3516dzUJWN.png' },
'jHxM4UK3Khxr7v8PpDguQh.png': { uri: '/images/jHxM4UK3Khxr7v8PpDguQh.png' },
'gKZ3CD8cFAC6JtwLGzVCLk.png': { uri: '/images/gKZ3CD8cFAC6JtwLGzVCLk.png' },
'5ytWBEYhavMMA9xmkkLpe5.png': { uri: '/images/5ytWBEYhavMMA9xmkkLpe5.png' },
'pDCrVMzvUW3yBYFTxQCWNc.png': { uri: '/images/pDCrVMzvUW3yBYFTxQCWNc.png' },
'dwevKFmrKB4oScexfe6xMm.png': { uri: '/images/dwevKFmrKB4oScexfe6xMm.png' },
'kxYo8rcDSh1e45Yp9JRobr.png': { uri: '/images/kxYo8rcDSh1e45Yp9JRobr.png' },
'hu4AvW4ex1oosQgzmQBEth.png': { uri: '/images/hu4AvW4ex1oosQgzmQBEth.png' },
'sTbq4r5X4SymAoSzwS8mwr.png': { uri: '/images/sTbq4r5X4SymAoSzwS8mwr.png' },
'de9bZYQyTmKPwNmtRmzB6G.png': { uri: '/images/de9bZYQyTmKPwNmtRmzB6G.png' },
'5y3EghQkVeyoZv4txFv24N.png': { uri: '/images/5y3EghQkVeyoZv4txFv24N.png' },
'n7xUzA1fn79BJj1gZomd4k.png': { uri: '/images/n7xUzA1fn79BJj1gZomd4k.png' },
'vWE97fNu7kBX2KtHDpB3BF.jpg': { uri: '/images/vWE97fNu7kBX2KtHDpB3BF.jpg' },
'5QeqwQjGFHZXtzqyDAkYEi.png': { uri: '/images/5QeqwQjGFHZXtzqyDAkYEi.png' },
'2qTDpA8w5S9nAhA5h2KWUD.png': { uri: '/images/2qTDpA8w5S9nAhA5h2KWUD.png' },
'qEfg5K3BGdm6M73C8sZj3q.png': { uri: '/images/qEfg5K3BGdm6M73C8sZj3q.png' },
'fraweciMPHdgcWix2sK63H.jpg': { uri: '/images/fraweciMPHdgcWix2sK63H.jpg' },
'dGZNmYJmiqfffGNckAG5bH.png': { uri: '/images/dGZNmYJmiqfffGNckAG5bH.png' },
'kBdCjH3SFwsgXjL2uxAYfv.jpg': { uri: '/images/kBdCjH3SFwsgXjL2uxAYfv.jpg' },
'mDzee3ndD6sXnU6HzPUFUC.png': { uri: '/images/mDzee3ndD6sXnU6HzPUFUC.png' },
'wwscYcubkqyzdD5Qq363gX.jpg': { uri: '/images/wwscYcubkqyzdD5Qq363gX.jpg' },
'32pTJ8a5DUGMyGJ95Vmycc.jpg': { uri: '/images/32pTJ8a5DUGMyGJ95Vmycc.jpg' },
'jJMoTHXtToWdESGyPamrog.jpg': { uri: '/images/jJMoTHXtToWdESGyPamrog.jpg' },
'rVqF21q4tPRpjnVF344s6g.jpg': { uri: '/images/rVqF21q4tPRpjnVF344s6g.jpg' },
'fKNHTHG2XniqpuTUYYNBkW.jpg': { uri: '/images/fKNHTHG2XniqpuTUYYNBkW.jpg' }
}

export default imageStaticSourcesByFileName
